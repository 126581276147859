/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-04-28",
    versionChannel: "nightly",
    buildDate: "2024-04-28T00:06:37.266Z",
    commitHash: "b0443b3b5f2681b91b487d1b3987ad6f3b9a4517",
};
